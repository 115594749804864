.meta-table {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.flight-infor {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  border: 1px solid #e0e3e5;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.transit-detail-container {
  border: 1px solid #e0e3e5;
  border-top: 0;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.flight-infor:hover {
  cursor: pointer;
}

.bridge {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
}

.border-me {
  width: 40%;
  border-top: 2px solid #545860;
}

.dot-me {
  width: 7.5px;
  height: 7.5px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  margin-left: 1%;
  margin-right: 1%;
}

.detail {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.detail-from-to {
  display: flex;
  flex-direction: column;
}

.airline-name {
  display: flex;
  justify-content: center;
}

.duration {
  margin-right: 15px;
}

.time-flight {
  margin-right: 15px;
}

.from-to {
  display: flex;
  font-weight: bold;
}

.transit-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.meta-table-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.card-outlined {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 15px;
}

.card-outlined:hover {
  cursor: pointer;
}

.hidden {
  display: none;
}

.from {
  margin-left: 10px;
  margin-right: 10px;
}

.to {
  margin-left: 10px;
}
